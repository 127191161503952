import React, { useEffect, useContext, useRef } from "react";
import axios from "axios";
import jwt from 'jwt-decode';

// analytics
import GoogleAnalytics from "analytics/googleAnalytics";
import NaverAnalytics from "analytics/naverAnalytics";
import BeusableAnalytics from "analytics/beusableAnalytics";
// utils
import ChangeDocumentTitle from "utils/changeDocumentTitle"
import Router from './Router';
// context
import { SpinnerContext } from "context/SpinnerContext";
// config
import { ADDRESS_API_AUTH } from "axios/config";
// commons
import { getAccessToken, setAccessToken } from 'commons/Token/tokenStorage'


const App = () => {
  return (
    <>
        <ChangeDocumentTitle />
        
        <AxiosTokenInterceptor />
        <AxiosSpinnerInterceptor />
        
        <GoogleAnalytics />
        <NaverAnalytics />
        <BeusableAnalytics />

        
        <Router />
    </>
    // Router 위에 Axios Interceptor 배치 필요(순서 변경 금지)
  );
}
export default App;


const AxiosTokenInterceptor = () => {

  const isLogoutAlert = useRef(false);

  useEffect(() => {
    //axios 호출시 인터셉트
    axios.interceptors.request.use(async (config) => {
      if(config.headers.Authorization){

        let accessToken = getAccessToken();
        const isNormalToken = tokenNormalCheck(accessToken);

        if (isNormalToken) {
          const isRefresh = tokenExpireCheck(accessToken);

          // accessToken이 refresh가 필요한 상태
          if (isRefresh) {
            const newAccessToken = await refetchAccessToken();

            if(tokenNormalCheck(newAccessToken)){
              setAccessToken(newAccessToken);
              accessToken = newAccessToken;
            }
            else{
              if(isLogoutAlert.current) {
                axios.Cancel('Access Token Expired');
                return;
              }

              isLogoutAlert.current = true;
              setAccessToken("")
              alert("로그인 기간이 만료되었습니다.");
              window.location.replace("/");
              axios.Cancel('Access Token Expired');
              return;
            }
          }
          else isLogoutAlert.current = false;

          config.headers.Authorization = `Bearer ${accessToken}`
        }
      }

      return config;
    }, 
    (error) => { return Promise.reject(error) }
    );
  // eslint-disable-next-line
  }, []);

  return null;
}


const AxiosSpinnerInterceptor = () => {
  const { setLoading } = useContext(SpinnerContext);

  useEffect(() => {

    // Axios Request Interceptor
    axios.interceptors.request.use(config => {
      if (config.url.includes('v2')) {
        setLoading(true)
      }
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    // Axios Response Interceptor
    axios.interceptors.response.use(function (response) {
      setTimeout(function () {
        setLoading(false)
      }, 100);

      return response;
    }, function (error) {
      setTimeout(function () {
        setLoading(false)
      }, 100);
      return Promise.reject(error);
    });
  // eslint-disable-next-line
  }, []);

  return null
}

const tokenNormalCheck = (token) => {
  try{
    jwt(token)
    return true;
  }
  catch(e){ return false }
}

const tokenExpireCheck = (token) => {
  return Date.now() > jwt(token).expireAt ? true : false
}

const refetchAccessToken = async () => {
  // "https://dev.auth.yellowbus.services/v2/refresh/cookie")
  return await axios.post(`${ADDRESS_API_AUTH}/v2/refresh/cookie`)
  .then(({ data }) => data?.data)
  .catch(e => e)
}