import { useEffect } from "react";
import { useLocation } from 'react-router-dom'; // 설치한 패키지


const ChangeDocumentTitle = () => {
    const location = useLocation();

    useEffect(() => {
        let searchTitle = location.pathname;

        return;     // 리뉴얼 작업 때 추가 된 return;

        // path에 id 가 들어가는 경로들
        // 예약 관리 상세
        if (searchTitle.includes("/reservation-management/")) {
            searchTitle = "/reservation-management/:orderId"
        }
        // 결제 내역 상세
        else if (searchTitle.includes("/payment-history/list/detail/")) {
            searchTitle = "/payment-history/list/detail/:orderId"
        }
        // 결제 내역 취소
        else if (searchTitle.includes("/payment-history/cancel/")) {
            searchTitle = "/payment-history/cancel/:orderId"
        }
        // 공지사항 상세
        else if (searchTitle.includes("/notice/")) {
            searchTitle = "/notice/:id"
        }
        // 승차권 예약 - 결제완료
        else if (searchTitle.includes("/reservation/complete")) {
            searchTitle = "/reservation/complete"
        }


        /**
         * 외부 링크 예외 처리
         */
        if(searchTitle.includes("/qr_carbanner1")){
            return;
        }
        else if(searchTitle.includes("/corp1_home")){
            return;
        }
        else if(searchTitle.includes("/carrot_sc1")){
            return;
        }
        else if(searchTitle.includes("/carrot_dt1")){
            return;
        }
        else if(searchTitle.includes("/mom_sc1")){
            return;
        }
        else if(searchTitle.includes("/mom_dt1")){
            return;
        }
        else if(searchTitle.includes("/sms_open1")){
            return;
        }
        
        try { titleMap[searchTitle]() }
        catch (e) { titleMap["/unknown"]() }
    }, [location]);

    return <></>;
}
export default ChangeDocumentTitle;



const changeTitle = (title) => {
    document.title = title;
}

// 전체 페이지 마다 useEffect로 window.gtag set으로 하기엔 유지보수 측면에서도 힘들기 때문에
// object key 접근의 경우 시간복잡도가 O(1)라서 title set에 object method 방식을 사용
const titleMap = {
    "/"() { 
        changeTitle("로그인") 
    },
    // 아이디 찾기
    "/find/email/write-info"(){ 
        changeTitle("아이디 찾기_정보 입력") 
    },
    "/find/email/identity-verification"(){ 
        changeTitle("아이디 찾기_본인 인증") 
    },
    "/find/email/complete"(){ 
        changeTitle("아이디 찾기_아이디 찾기 완료") 
    },
    // 비밀번호 찾기
    "/find/pwd/write-info"(){ 
        changeTitle("비밀번호 찾기_정보 입력") 
    },
    "/find/pwd/identity-verification"(){ 
        changeTitle("비밀번호 찾기_본인 인증") 
    },
    "/find/pwd/change-pwd"(){ 
        changeTitle("비밀번호 찾기_재설정") 
    },
    "/find/pwd/complete"(){ 
        changeTitle("비밀번호 찾기_비밀번호 찾기 완료") 
    },
    // 회원가입
    "/sign-up/terms-of-service"(){ 
        changeTitle("회원가입_약관 동의") 
    },
    "/sign-up/write-privacy"(){ 
        changeTitle("회원가입_정보 입력") 
    },
    "/sign-up/identity-verification"(){ 
        changeTitle("회원가입_본인 인증") 
    },
    "/sign-up/write-id"(){ 
        changeTitle("회원가입_아이디 입력") 
    },
    "/sign-up/write-pw"(){ 
        changeTitle("회원가입_비밀번호 입력") 
    },
    "/sign-up/complete"(){ 
        changeTitle("회원가입_회원가입 완료") 
    },
    // 내 정보
    "/info/my"(){ 
        changeTitle("내 정보") 
    },
    "/info/my/change-password/validation"(){ 
        changeTitle("내 정보_현재 비밀번호 입력(비밀번호 변경)") 
    },
    "/info/my/change-password/new"(){ 
        changeTitle("내 정보_비밀번호 변경") 
    },
    "/info/my/change-phone/validation"(){ 
        changeTitle("내 정보_현재 비밀번호 입력(번호 수정)") 
    },
    "/info/my/change-phone/write-phone"(){ 
        changeTitle("내 정보_휴대폰 번호 수정")
    },
    "/info/my/change-phone/identity-verification"(){ 
        changeTitle("내 정보_휴대폰 본인 인증")
    },
    // 학생 정보
    "/info/student"(){ 
        changeTitle("학생 정보") 
    },
    "/info/student/update/validation"(){ 
        changeTitle("학생 정보_현재 비밀번호 입력") 
    },
    "/info/student/update/write-info"(){ 
        changeTitle("학생 정보_학생 정보 수정") 
    },
    "/info/student/append/write-info"(){ 
        changeTitle("학생 정보_학생 등록") 
    },
    // 승차권 예약
    "/reservation/type"(){ 
        changeTitle("승차권 예약_예약 방식 선택") 
    },
    "/reservation/select-date"(){ 
        changeTitle("승차권 예약_날짜 선택") 
    },
    "/reservation/bus-stop"(){ 
        changeTitle("승차권 예약_정류장 선택") 
    },
    "/reservation/select-ticket"(){ 
        changeTitle("승차권 예약_승차권 선택") 
    },
    "/reservation/purchases"(){ 
        changeTitle("승차권 예약_추가 구매 승차권") 
    },
    "/reservation/payment-info"(){ 
        changeTitle("승차권 예약_승차권 정보")
    },
    "/reservation/ticket-payment"(){ 
        changeTitle("승차권 예약_결제 정보")
    },
    "/reservation/complete"(){ 
        changeTitle("승차권 예약_결제 완료")
    },
    // 결제 내역
    "/payment-history/list"(){ 
        changeTitle("결제 내역")
    },
    "/payment-history/list/detail/:orderId"(){ 
        changeTitle("결제 내역_상세")
    },
    "/payment-history/cancel/:orderId"(){ 
        changeTitle("결제 내역_결제 취소")
    },
    // 예약 관리
    "/reservation-management"(){ 
        changeTitle("예약 관리")
    },
    "/reservation-management/:orderId"(){ 
        changeTitle("예약 관리_상세")
    },
    // 공지사항
    "/notice"(){ 
        changeTitle("공지사항")
    },
    "/notice/:id"(){ 
        changeTitle("공지사항_상세")
    },
    // 고객센터
    "/help-desk"(){ 
        changeTitle("고객센터")
    },
    // 404
    "/unknown"(){ 
        changeTitle("404")
    },
}
