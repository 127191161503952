import { useEffect } from "react";


// const deploymentEnvironment = "localhost";
// const deploymentEnvironment = "develop";
const deploymentEnvironment = "release";
// const deploymentEnvironment = "none";


const GoogleAnalytics = () => {

    useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === deploymentEnvironment) {
            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments) }
            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', process.env.REACT_APP_CONFIG_ID);

            // sendBeacon API를 지원하지 않는 브라우저 환경에서는 모든 이벤트가 즉시 전송됩니다.
            // sendBeacon에 빈 함수를 bind하거나 반환값을 무조건 false로 return 함수를 넣어봤지만 null로 넣어야 이벤트를 즉시 전송함
            // https://support.google.com/analytics/answer/9322688?hl=ko
            navigator.sendBeacon = null;
        }
    }, []);

    return <></>;
}
export default GoogleAnalytics;

